import useSWR from "swr";
import { useState } from "react";
import { URLS } from "../../services/urls";
import { useTranslation } from "react-i18next";
import JobTabContent from "./components/JobTabContent";
import { useNavigate } from "react-router-dom";
import PopCategory from "./components/PopCategory";
import JobsByLocation from "./components/JobsByLocation";
import News from "./components/News";
import HomeBanner from "./components/BannerSlider";
import _ from "lodash";
import JobProcess from "./components/JobProcess";
import Testimonies from "./components/Testimonies";
import NewsLetter from "../../components/newsletter";
import OldJob from "./components/OldJob";
import { API } from "../../services/axios";
import { getAvailableCategories, hasOpenedJobs } from "../../utils/fonctions";
const fetcher = (url) => API.get(url).then(res => {
  let resp = [] 
  res.data.results.forEach(element => {
    if(element.name==="Douala" || element.name==="Yaoundé" || element.name==="Bafoussam" || element.name==="Garoua" || 
    element.name==="Maroua"|| element.name==="Bamenda" || element.name==="Buea" || element.name==="Bertoua" || element.name==="Kribi"){
      resp.push(element)
    }
  });
  return resp});

const HomePage = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [showIndustries, setShowIndustries] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const { data: categories, isLoading: loadingCategories } = useSWR(
    URLS.CONFIGS.PROFILE.SECTOR.list("",1500)
  );

  const { data: locations, isLoading: loadingLocations } = useSWR(
    URLS.CONFIGS.PROFILE.COUNTRIES.CITIES.cmr(""), fetcher
  );

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(
      `/search?search=${search}&industry=${category?.id ?? ""}&location=${
        location?.name ?? ""
      }`
    );
  };
// console.log(categories);
  return (
    <>
      <section className="section-box mt-95">
        <div className="banner-hero hero-2">
          <div className="banner-inner">
            <div className="block-banner">
              <h1
                className="text-42 color-white wow animate__animated animate__fadeInUp"
                dangerouslySetInnerHTML={{ __html: t("textJobBoard") }}
              ></h1>
              <div
                className="font-lg font-regular color-white mt-20 wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              ></div>
              <div
                className="form-find mt-40 wow animate__animated animate__fadeIn"
                data-wow-delay=".2s"
              >
                <form onSubmit={handleSubmit}>
                  <div className="job_filters_wrap d-md-flex">
                    <div className="box-industry">
                      {showIndustries && (
                        <div className="dropdown" id="dropdown">
                          {categories?.results?.map((cat) => (
                            <a
                              href="#"
                              className={
                                category?.id === cat.id ? "active" : ""
                              }
                              key={cat?.id}
                              onClick={() => {
                                setCategory(cat);
                                setShowIndustries(false);
                                navigate(`/search?industry=${cat?.id ?? ""}`);
                              }}
                            >
                              <i className="bx bx-plus-circle"></i>
                              {cat?.name}
                            </a>
                          ))}
                        </div>
                      )}
                      <label
                        htmlFor="search_category"
                        className="select2 select2-container select2-container--default elect2-container--open"
                        dir="ltr"
                        style={{ width: "158.359375px" }}
                        onClick={() => setShowIndustries(!showIndustries)}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single"
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabindex="0"
                            aria-labelledby="select2-search_category-56-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-search_category-56-container"
                              title="Industry"
                            >
                              {category?.name ?? t("textIndustry")}
                            </span>
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation"></b>
                            </span>
                          </span>
                        </span>
                        <span
                          className="dropdown-wrapper"
                          aria-hidden="true"
                        ></span>
                      </label>
                    </div>
                    <div className="box-industry">
                      {showLocation && (
                        <div className="dropdown" id="dropdown">
                          {locations?.map((cat) => (
                            <a
                              href="#"
                              className={
                                location?.id === cat.id ? "active" : ""
                              }
                              key={cat?.id}
                              onClick={() => {
                                setLocation(cat);
                                setShowLocation(false);
                                navigate(`/search?location=${cat?.name ?? ""}`);
                              }}
                            >
                              <i className="bx bx-plus-circle"></i>
                              {cat?.name}
                            </a>
                          ))}
                        </div>
                      )}
                      <label
                        className="select2 select2-container select2-container--default card-location"
                        dir="ltr"
                        style={{ width: "91.75px" }}
                        onClick={() => setShowLocation(!showLocation)}
                      >
                        <span className="selection">
                          <span
                            className="select2-selection select2-selection--single "
                            role="combobox"
                            aria-haspopup="true"
                            aria-expanded="false"
                            tabindex="0"
                            aria-labelledby="select2-search_location-container"
                          >
                            <span
                              className="select2-selection__rendered"
                              id="select2-search_location-container"
                              title="Location"
                            >
                              {location?.name ?? t("textLocation")}
                            </span>
                            <span
                              className="select2-selection__arrow"
                              role="presentation"
                            >
                              <b role="presentation"></b>
                            </span>
                          </span>
                        </span>
                        <span
                          className="dropdown-wrapper"
                          aria-hidden="true"
                        ></span>
                      </label>
                    </div>

                    <input
                      className="form-input input-keysearch mr-10"
                      type="text"
                      name="search_keywords"
                      id="search_keywords"
                      placeholder={t("textkeyword")}
                    />

                    <input
                      className="btn btn-default btn-find font-sm"
                      type="submit"
                      value={t("textSearch")}
                    />
                  </div>
                </form>
              </div>
              <div
                className="list-tags-banner mt-20 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
              >
                <strong>{t("textpop")}:</strong>
                <a href="#">{t("textDesigner")}</a>, <a href="#">Web</a>,{" "}
                <a href="#">IOS</a>, <a href="#">{t("textDeveloper")}</a>,{" "}
                <a href="#">PHP</a>, <a href="#">Senior</a>,{" "}
                <a href="#">{t("textEngineer")}</a>
              </div>
            </div>
          </div>
          <div className="mt-60">
            {!loadingCategories && (
              <HomeBanner categories={categories?.results?getAvailableCategories(categories?.results): []} />
            )}
          </div>
        </div>
        {/* <Clients /> */}
      </section>
      <OldJob />
      <section className="section-box mt-30">
        <div className="container">
          <div className="text-start">
            <h2 className="section-title text mb-10 wow animate__animated animate__fadeInUp">
              {t("dayJob")}
            </h2>
            <p className="font-lg text-danger color-text-paragraph-2 wow animate__animated animate__fadeInUp">
              {t("findJob")}
            </p>
            <div className="d-flex">
              {loadingCategories && (
                <div
                  className="spinner-border m-auto spinner-border-md"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
            <div className="list-tabs mt-40">
              <ul className="nav nav-tabs" role="tablist">
                {!loadingCategories &&
                  _.orderBy(
                    categories?.results,
                    [
                      "jobs",
                      function (o) {
                        return o.length;
                      },
                    ],
                    ["desc"]
                  )
                    ?.slice(0, 7)
                    ?.map((category, index) => {
                      return hasOpenedJobs(category) ? (
                        <li key={category.id}>
                          <a
                            className={index === 0 ? "active" : ""}
                            id={`nav-tab-job-${category.id}`}
                            href={`#tab-job-${category.id}`}
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls={`tab-job-${category.id}`}
                            aria-selected="true"
                          >
                            {/* <img src="assets/imgs/page/homepage1/management.svg" alt="Cible rh emploie" /> */}
                            {category?.name}
                          </a>
                        </li>
                      ) : null;
                    })}
                {/* <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="false"><img src="assets/imgs/page/homepage1/marketing.svg" alt="Cible rh emploie" /> Marketing &amp; Sale</a></li>
                <li><a id="nav-tab-job-3" href="#tab-job-3" data-bs-toggle="tab" role="tab" aria-controls="tab-job-3" aria-selected="false"><img src="assets/imgs/page/homepage1/finance.svg" alt="Cible rh emploie" /> Finance</a></li>
                <li><a id="nav-tab-job-4" href="#tab-job-4" data-bs-toggle="tab" role="tab" aria-controls="tab-job-4" aria-selected="false"><img src="assets/imgs/page/homepage1/human.svg" alt="Cible rh emploie" /> Human Resource</a></li>
                <li><a id="nav-tab-job-5" href="#tab-job-5" data-bs-toggle="tab" role="tab" aria-controls="tab-job-5" aria-selected="false"><img src="assets/imgs/page/homepage1/retail.svg" alt="Cible rh emploie" /> Retail &amp; Products</a></li>
                <li><a id="nav-tab-job-6" href="#tab-job-6" data-bs-toggle="tab" role="tab" aria-controls="tab-job-6" aria-selected="false"><img src="assets/imgs/page/homepage1/content.svg" alt="Cible rh emploie" /> Content Writer</a></li> */}
              </ul>
            </div>
          </div>
          <div className="mt-50">
            <div className="tab-content" id="myTabContent-1">
              {!loadingCategories &&
                _.orderBy(
                  categories?.results,
                  [
                    "jobs",
                    function (o) {
                      return o.length;
                    },
                  ],
                  ["desc"]
                )
                  ?.slice(0, 7)
                  ?.slice(0, 7)
                  ?.map((category, index) => {
                    return category?.jobs?.length > 0 ? (
                      <div
                        key={category?.id}
                        className={`tab-pane fade ${
                          index === 0 ? "show active" : ""
                        }`}
                        id={`tab-job-${category?.id}`}
                        role="tabpanel"
                        aria-labelledby={`tab-job-${category?.id}`}
                      >
                        <JobTabContent
                          jobs={category?.jobs}
                          category={category.id}
                        />
                      </div>
                    ) : null;
                  })}
            </div>
          </div>
        </div>
      </section>
      <PopCategory categories={categories?.results ?? []} />
      <JobsByLocation locations={locations ?? []} />
      {/* <JobsStats /> */}
      <JobProcess />
      {/* <Testimonies /> */}
      {/* <section className="section-box mt-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 mb-15 mb-lg-0">
              <div className="box-radius-8 bg-urgent hover-up">
                <div className="image">
                  <figure><img src="assets/imgs/page/homepage2/job-tools.png" alt="Cible rh emploie" /></figure>
                </div>
               <div className="text-info">
                  <h3>Job Tools Services</h3>
                  <p className="font-sm color-text-paragraph-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam laoreet rutrum quam, id faucibus erat interdum a. Curabitur eget tortor a nulla interdum semper.</p>
                  <div className="mt-15"><a className="btn btn-arrow-right" href="#">Find Out More</a></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="box-radius-8 bg-9 hover-up">
                <div className="image">
                  <figure><img src="assets/imgs/page/homepage2/planning-job.png" alt="Cible rh emploie" /></figure>
                </div>
                <div className="text-info">
                  <h3>Planning a Job?</h3>
                  <p className="font-sm color-text-paragraph-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam laoreet rutrum quam, id faucibus erat interdum a. Curabitur eget tortor a nulla interdum semper.</p>
                  <div className="mt-15"><a className="btn btn-arrow-right" href="#">Find Out More</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>  s
      </section> */}
      {/* <News /> */}
      <NewsLetter />
      <script src="/assets/js/plugins/counterup.js"></script>
    </>
  );
};

export default HomePage;

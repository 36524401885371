export const URLS = {
  // BASE_URL: 'http://127.0.0.1:8000',
  // BASE_URL: "https://api.cible-emploie.we-techcorp.com",
  BASE_URL: "https://api.ciblerh-emploi.com",
  CLIENT_ID: "rgFz0scCkvH2qAm4iBWwk5m8f6qC9SVM5RHzrKze",
  AUTHS: {
    LOGIN: "auth/mlogin/",
    // LOGIN: "auth/login/",
    REFRESH: "auth/token/refresh/",
    LOGOUT: "auth/logout/",
    REGISTRATION: "user/accounts/registration/",
    MANAGER_REGISTRATION: "accounts/staff/registration/",
    VERIFY_EMAIL: (key) => `user/accounts/confirm-email/${key}/`,
    VERIFY_ACCOUNT: (id) => `/users/verify/${id}/`,
    resetPassword: "accounts/password-reset/",
    resetPasswordAdmin: "accounts/password/admin-reset/",
    me: "users/me/",
    PASSWORD: {
      reset: "auth/password/reset/",
      confirm: "auth/password/reset/confirm/",
    },
    VERIFY: {
      email: "user/accounts/email/verification/",
      resend: "/user/accounts/resend-email/",
    },
  },
  DASHBOARD: {
    list: "dashboard/user/",
  },
  CONFIGS: {
    PROFILE: {
      SKILLS: {
        create: "configs/profile/skills/",
        list: (search) => `configs/profile/skills/?search=${search}`,
      },
      COLLEGE: {
        create: "configs/profile/college/",
        list: (search) => `configs/profile/college/?search=${search}`,
      },
      COMPANY: {
        create: "configs/profile/company/",
        list: (search) => `configs/profile/company/?search=${search}`,
      },
      SECTOR: {
        create: "configs/profile/industry/",
        list: (search,limit=1000) => `configs/profile/industry/?search=${search}&limit=${limit}`,
      },
      COUNTRIES: {
        CITIES: {
          list: (search) =>
            `configs/profile/countries/cities/?search=${search}`,
          cmr: (search) => `configs/profile/villes/?search=${search}&limit=500`,
        },
        list: (search) => `configs/profile/industry/?search=${search}`,
      },
      DIPLOMA: {
        create: "configs/profile/diploma/",
        list: (search) => `configs/profile/diploma/?search=${search}`,
      },
      PREFESSION: {
        create: "configs/profile/profession/",
        list: (search) =>
          `/configs/profile/profession/?search=${search}&limit=1000`,
      },
    },
  },
  USERS: {
    APPLICATIONS: {
      myApplications: (search, page) =>
        `users/my_applications/?search=${search}&page=${page}`,
    },
    PROFILE: {
      my: "users/my_profiles/",
      markAsDefault: (id) => `users/profile/${id}/set_as_default/`,
      create: "users/profile/",
      list: (search, page) => `users/profile/?search=${search}&page=${page}`,
      myProfiles: (search, page) =>
        `users/my_profiles/?search=${search}&page=${page}`,
      update: (id) => `users/profile/${id}/`,
      details: (id) => `users/profile/${id}/`,
      EDUCATION: {
        create: "users/profile/education/",
        update: (id) => `users/profile/education/${id}/`,
        details: (id) => `users/profile/education/${id}/`,
      },
      EXPERIENCE: {
        create: "users/profile/experience/",
        update: (id) => `users/profile/experience/${id}/`,
        details: (id) => `users/profile/experience/${id}/`,
      },
      CERTIFICATION: {
        create: "users/profile/certification/",
        update: (id) => `users/profile/certification/${id}/`,
        details: (id) => `users/profile/certification/${id}/`,
      },
      LANGUAGES: {
        create: "users/profile/language/",
        update: (id) => `users/profile/language/${id}/`,
        details: (id) => `users/profile/language/${id}/`,
      },
      REFERENCE: {
        create: "users/profile/reference/",
        update: (id) => `users/profile/reference/${id}/`,
        details: (id) => `users/profile/reference/${id}/`,
      },
      setAsDefault: (id) => `users/profile/${id}/set_as_default/`,
    },
    update: (id) => `users/${id}/`,
  },
  JOBS: {
    list: (search) => `/jobs/?search=${search}/`,
    history: (search) => `/jobs/history/?search=${search}/`,
    details: (slug) => `/jobs/${slug}/`,
    historyDetails: (slug) => `/jobs/history/${slug}/`,
    apply: (slug) => `/jobs/${slug}/apply/`,
    byCategory: (categoryId) => `/jobs/?industry=${categoryId}/`,
    byIndustry: (name) => `/jobs/?industry_name=${name}/`,
    search: (page, search, category, location) =>
      `/jobs/?page=${page ?? 1}&search=${search ?? ""}${
        category ? "&industry__id=" + category : ""
      }${location ? "&city__name=" + location : ""}`,
    APPLICATIONS: {
      details: (id) => `jobs/applications/${id}/`,
      cancel: (id) => `jobs/applications/${id}/cancel/`,
      accept: (id) => `jobs/applications/${id}/accept/`,
    },
    ANSWERS: {
      create: `jobs/related_question_answer/`,
    },
  },
  NOTIFICATIONS: {
    messages: (search, page) =>
      `notifications/messages/?search=${search}page=${page ?? 1}`,
    details: (id) => `notifications/messages/${id}/`,
  },
  NEWSLETTER: {
    SUBSCRIPTION: {
      create: "newsletter/subscriptions/",
      list: "newsletter/subscriptions/",
    },
  },
  CAROUSSEL: {
    create: "caroussel-content/",
    list: (search) => `caroussel-content/?search=${search}`,
    update: (id) => `caroussel-content/${id}/`,
    delete: (id) => `caroussel-content/${id}/`,

    CARROUSEL_CONTENT: {
      list: (search) => `caroussel-content/images/?search=${search}`,
    },
  },
};

const hasOpenedJobs=(category)=>{
    // checks if a category has opened jobs
    var resp=false;
    for(let i=0; i<category?.jobs?.length; i++){
        if(!category?.jobs[i].closed && category?.jobs[i].validated && category?.jobs[i].active){
            resp = true;
            break;
        }
    }
    return resp;
}

const getAvailableCategories = (categories)=>{
    var categories_list = [];
    for(let i=0; i<categories?.length; i++){
        if(categories[i]?.jobs?.length>0){
            categories_list.push(categories[i]);}
    }
    // for(let i=0; i<categories?.length; i++){
    //     if(hasOpenedJobs(categories[i])){
    //         categories_list.push(categories[i]);}
    // }
    // console.log(categories_list)
    return categories_list;
}
export {hasOpenedJobs, getAvailableCategories}